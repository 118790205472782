// import React, { useEffect } from 'react'
// import { useTranslation } from 'react-i18next';

// function Pagination({ currentPage, setCurrentPage, totalPages }) {
//     const { t } = useTranslation();
//     useEffect(() => {
//         window.scrollTo({ top: 0 });
//       }, [currentPage]);
//     return (
//         <div className='pagination'>
//             <div className='item'
//                 onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
//                 style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer', opacity: currentPage === 1 ? 0.5 : 1 }}>{t("Geri")}</div>
//             {Array.from({ length: totalPages }, (_, i) => {
//                 let index = i + 1
//                 const isActive = currentPage === index;
//                 return (<div style={{cursor:'pointer'}} className={`item ${isActive ? 'pagination-active' : ''}`}
//                     key={index}
//                     onClick={() => setCurrentPage(index)}
//                     disabled={isActive}>{index}</div>)
//             })}
//             <div className='item'
//                 onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage)}
//                 style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', opacity: currentPage === totalPages ? 0.5 : 1 }}>{t("İrəli")} </div>

//         </div>
//     );
// }

// export default Pagination
import React, { useEffect } from 'react';


function Pagination({ currentPage, setCurrentPage, totalPages }) {


  // Функция для вычисления страниц для отображения
  const getPagesToDisplay = () => {
    let pages = [];

    if (totalPages <= 4) {
      // Если страниц 4 или меньше, показываем все
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Всегда показываем первую страницу
      pages.push(1);

      // Если текущая страница больше 2, добавляем многоточие перед текущей страницей
      if (currentPage > 2) {
        pages.push('...');
      }

      // Показываем страницы вокруг текущей страницы
      if (currentPage !== 1 && currentPage !== totalPages) {
        pages.push(currentPage);
      }

      // Если текущая страница меньше последней страницы - показываем следующую
    //   if (currentPage < totalPages - 1) {
    //     pages.push(currentPage + 1);
    //   }

      // Показываем многоточие перед последней страницей, если текущая страница не рядом с последней
      if (currentPage <= totalPages - 2) {
        pages.push('...');
      }

      // Всегда показываем последнюю страницу
      pages.push(totalPages);
    }

    // Убираем повторяющиеся страницы, но сохраняем многоточие
    let uniquePages = [];
    let lastPage = null;
    pages.forEach(page => {
      if (page !== '...' || lastPage !== '...') {
        uniquePages.push(page);
      }
      lastPage = page;
    });

    return uniquePages;
  };

  // Прокручиваем окно вверх при изменении страницы
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  const pages = getPagesToDisplay();

  return (
    <div className="pagination">
      <div
        className="item"
        onClick={() =>
          setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
        }
        style={{
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          opacity: currentPage === 1 ? 0.5 : 1,
        }}
      >
        <svg width="30px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.3508 12.7499L11.2096 17.4615L10.1654 18.5383L3.42264 11.9999L10.1654 5.46148L11.2096 6.53833L6.3508 11.2499L21 11.2499L21 12.7499L6.3508 12.7499Z" fill="#000"/>
</svg>
        {/* {t('Geri')} */}
        
      </div>

      {pages.map((page, index) => (
        <div
          key={index}
          className={`item ${page === currentPage ? 'pagination-active' : ''}`}
          onClick={() => {
            if (page !== '...') setCurrentPage(page);
          }}
          style={{
            cursor: page === '...' ? 'default' : 'pointer',
            opacity: page === '...' ? 0.5 : 1,
          }}
        >
          {page}
        </div>
      ))}

      <div
        className="item"
        onClick={() =>
          setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage)
        }
        style={{
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
          opacity: currentPage === totalPages ? 0.5 : 1,
        }}
      >
      <svg width="30px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6492 11.2501L12.7904 6.53852L13.8346 5.46167L20.5774 12.0001L13.8346 18.5385L12.7904 17.4617L17.6492 12.7501H3V11.2501H17.6492Z" fill="#000"/>
</svg>
        {/* {t('İrəli')} */} 
      </div>
    </div>
  );
}

export default Pagination;
