import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Layout from "./views/Layout";
import Home from "./views/Home";
import Services from "./views/Services";
import Activity from "./views/Activity";
import About from "./views/About";
import ServiceInfo from "./views/ServiceInfo";
import 'swiper/css';
import './css/combined.css';
import { AuthProvider } from "./AuthContext";
import Login from "./views/Login";
import AdminHome from "./views/AdminHome";
import { useEffect } from "react";


function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({ top: 0 });
    }, [pathname]);
  
    return null;
  };
  return (
    <AuthProvider>
      <BrowserRouter>
       <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="service-info/:slug" element={<ServiceInfo />} />
            <Route path="activity" element={<Activity />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<Home/>}/>
          </Route>

          <Route exact path="/admin/login" element={<Login />} />
          <Route exact path="/admin/home" element={<AdminHome />}/>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
