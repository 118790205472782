import React, { useCallback, useEffect, useState } from 'react'
import setkayaIcon from '../image/Untitled-1-06.svg'
import { useTranslation } from 'react-i18next'
import WpIcon from '../image/icons/WpLinkIcon.png'
import { Base_url } from '../api_url'
function Footer() {
  const [footerData, setfooterData] = useState({});
  const [loading, setLoading] = useState(true);
  const { i18n,t } = useTranslation();
  const fetchServices = useCallback(async () => {
    try {
      const response = await fetch(
        `${Base_url}${i18n.language}/settings/`
      );
      if (!response.ok) {
        throw new Error('Secdiniz kategoriyaya uygun mehsul tapilmadi');
      }
      const data = await response.json();
      setfooterData(data.settings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [i18n.language]); // Мемоизируем функцию, добавив i18n.language как зависимость
  
  useEffect(() => {
    fetchServices(); // Загружаем сервисы при изменении языка
  }, [fetchServices]); // Добавляем fetchServices в зависимости
  
  if (loading) {
    return <p></p>;
  }
  return (
    <>
    <footer>
      <div className='container'>
        <div className='footer-top'>
          <div className='left'>
           <div className='footerLogo'>
            <img src={setkayaIcon}  alt='setkaya logo'/>

           </div>
            <p>{footerData?.slogan}</p>
            <div className='icons'>
              {
                footerData?.social_links?.[0]?.facebook && (
                  <a title={footerData?.social_links?.[0]?.facebook} rel="noreferrer" target='_blank' href={footerData?.social_links[0]?.facebook}><i className="fa-brands fa-facebook-f"></i></a>

                )
              }
              {
                footerData?.social_links?.[0]?.whatsapp && (
                  <a title={footerData?.social_links?.[0]?.whatsapp} rel="noreferrer" target='_blank' href={`https://wa.me/${footerData?.social_links?.[0]?.whatsapp}`}><i className="fa-brands fa-whatsapp"></i></a>

                )
              }
              {
                footerData?.social_links?.[0]?.instagram && (
                  <a title={footerData?.social_links?.[0]?.instagram} rel="noreferrer" target='_blank' href={footerData?.social_links?.[0]?.instagram}><i className="fa-brands fa-instagram"></i></a>

                )
              }
              {
                footerData?.social_links?.[0]?.tiktok && (
                  <a title={footerData?.social_links?.[0]?.tiktok} rel="noreferrer" target='_blank' href={footerData?.social_links?.[0]?.tiktok}><i className="fa-brands fa-tiktok"></i></a>

                )
              }
             
            </div>
          </div>
          
        </div>
        <div className='map'>
          <div className='contacts'>
          {footerData?.contacts?.[0]?.phone && (
            <p className='footer_containerLine'><span className='footer_line'>{t("Əlaqə")}</span>  <a href={`tel:${footerData?.contacts?.[0]?.phone.replace(/\s+/g, '')}`}>
            {footerData?.contacts?.[0]?.phone}
          </a>
           </p>
            )}  
          {footerData?.address && (
            <p className='footer_containerLine'><span className='footer_line'>{t("Ünvanı")}</span>  {footerData?.address}</p>
          )}
          </div>
          <div className='email'>
          {footerData?.contacts?.[0]?.email && (
            <p className='footer_containerLine'><span className='footer_line'>{t("E-poçt")}</span>  {footerData?.contacts?.[0]?.email}</p>
          )} 
          </div>
          
         
     
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3037.4597175430467!2d49.87603377601128!3d40.42081827143894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDI1JzE1LjAiTiA0OcKwNTInNDMuMCJF!5e0!3m2!1sru!2saz!4v1741982152677!5m2!1sru!2saz" 
            width="600" 
            height="450" 
            style={{ border: 0 }}
            allowfullscreen="" 
            loading="lazy" 
            title="Google Map"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className='footer-bottom'>
          <a href='https://digigo.work/' rel="noreferrer" target='_blank'>
          <p>{t('digigo')}</p>

          </a>
        </div>
      </div>
    </footer>
    {footerData?.social_links?.[0]?.whatsapp && (
       <a className='wp-link-icon' rel="noreferrer" target='_blank' href={`https://wa.me/${footerData?.social_links?.[0]?.whatsapp}`}>
       <img src={WpIcon} alt='whatsapp icon'/>
   </a>

    )}
    </>
  )
}

export default Footer